// let logos = ['./logo_v12.png', './logo_v13_flat_no_gloss.png', './logo_v13_flat.png']
// let whichOne = Math.round(Math.random()*2);
import logo from './logo_v18_flat.png';
import './App.css';

import React, { useState, useEffect } from 'react';

function Home() {
  return (
    <div>
      <p>Welcome to PK Systems!</p>
      <p>
We offer a wide range of services, including web and mobile development, software engineering, IT consulting, and more. Our experienced team is dedicated to helping our customers find the best solutions to their tech needs. We strive to stay on the cutting edge of technology and always provide the most up-to-date solutions.
</p>
<p>
We believe in providing the best and most reliable technology solutions to our customers. We are dedicated to understanding their needs and delivering products that meet their expectations. Our team of experienced professionals is always available to provide support and ensure that our customers get the best experience possible.
</p>
<p>
At our company, we are committed to providing excellent customer service and creating an environment that is both comfortable and professional. We believe in creating long-lasting relationships with our customers and providing them with the most reliable and up-to-date solutions.
</p>
<p>
If you have any questions or would like to learn more about our services, please don’t hesitate to contact us. We look forward to working with you!
</p>
<p>
  You can find more info in the following sections:
  </p>
  <p>
          1. Home: Introduction to the software company, services offered
          <br></br>2. Products: List of software products and descriptions
          <br></br>3. Services: Description of technical services offered such as implementation, customization, and maintenance
          <br></br>4. Support: Information about support options, such as a helpdesk, forums, and FAQs
          <br></br>5. Resources: Educational materials such as webinars, white papers and guides
          <br></br>6. About Us: Overview of the company and people
          <br></br>7. Contact Us: Information about how to contact customer support, primary e-mail: 
          <a href="mailto:
          contact@pk-systems.digital">contact@pk-systems.digital</a>
          </p>
    </div>
  )
}

function Products() {
  return (
    <div>
      <p>
At PK Systems, we specialize in custom software solutions that are tailored to meet the needs of our customers. We offer a range of products designed to streamline processes, improve efficiency, and increase productivity. Our products include: 
</p>

<ul>
  <li>Database systems</li>
  <li>CRM solutions</li>
  <li>ERP systems</li>
  <li>Cloud-based solutions</li>
  <li>Business intelligence tools</li>
  <li>Data analytics software</li>
  <li>E-commerce solutions</li>
  <li>Custom web applications</li>
  <li>Mobile applications</li>
  <li>Software integration services</li>
  <li>API development</li>
  <li>Cloud hosting services</li>
</ul>

Our experienced team of software engineers is committed to providing the highest quality of services to our customers. 
We believe in creating innovative and reliable solutions that are tailored to meet our customers’ needs. 
If you have any questions about our products or services, please don’t hesitate to <a href="mailto:
          contact@pk-systems.digital">contact us</a>.</div>
  )
}
function Services() {
  return (
    <div>
      <p>
We provide a wide range of technology services to businesses.
</p>

<p>
<b>Business Services</b>
</p>
<p>
We can design and implement custom IT solutions, including cloud-based services, network security, application development, and data storage. We also offer IT consulting, project management, and maintenance services to help ensure that your business’s technology remains up-to-date and runs smoothly.
</p>
    </div>
  )
}
function Support() {
  return (
    <div>
      <p>
      At PK Systems, our top priority is providing excellent customer service and support. We understand that our customers rely on our products and services to help them run their businesses and we strive to provide them with the best customer experience possible.
      </p>
      <p>
Our Support team is available 24 hours a day, 7 days a week to assist our customers with any technical issues they may have. We offer a variety of support options, including on-site technical support, remote support, and telephone support. Customers can also access our extensive online support resources, which include a knowledge base and technical forums.
</p>
<p>
Our knowledgeable and friendly support staff is always available to help customers with any technical issues they may have. We prioritize customer satisfaction and strive to provide timely and accurate resolutions to our customers’ issues.
</p>
<p>
If you have any questions or need technical help, please do not hesitate to contact us. Our team will be happy to assist you.
</p>
    </div>
  )
}
function Resources() {
  return (
    <div>
      <p>
1. Technical Support: Technical support staff are available to help with any technical issues a customer may have. This can include troubleshooting software, hardware, and network issues.
</p>
<p>
2.Technical documentation: Detailed documentation for the products and services offered by the company, including user guides, installation manuals, and API references.
</p>
<p>
3. User Guides: User guides are available to help customers understand the products and services offered by the company. These guides can be either online or printed.
</p>
<p>
4. Knowledge Base: A knowledge base is a collection of articles and resources that provide answers to common questions and solutions to common problems.
</p>
<p>
5. Online Tutorials: Online tutorials are available to help customers learn about specific products and services offered by the company.
</p>
<p>
6. Community Forums: Community forums are an excellent way for customers to ask questions and receive help from other users.
</p>
<p>
If you need more information, please do not hesitate to contact us. Our team will be happy to assist you.
</p>

{/* Technical documentation: Detailed documentation for the products and services offered by the company, including user guides, installation manuals, and API references.
Knowledge base: An online database of frequently asked questions and answers, covering a wide range of topics related to the company's products and services.
Community forums: An online platform for users to connect and share information, ask questions, and provide feedback.
Support center: A centralized hub for accessing technical support and customer service, including contact information, hours of operation, and online ticketing systems.
Blog: A company-run blog that provides in-depth articles and tutorials on relevant technology topics, as well as updates and news about the company and its products.
Webinars: Regularly scheduled online presentations and demonstrations, covering a range of topics related to the company's products and services.
Training resources: A collection of online training materials and resources, including video tutorials, online courses, and certification programs.
Downloadable resources: A library of helpful resources, such as white papers, case studies and data sheets, that provide additional information about the company and its products. */}
    </div>
  )
}

function AboutUs() {
  return (
    <div>
      <p>
      Welcome to our tech company! We are a team of innovators, engineers, and designers that believe in the power of technology. We are passionate about creating products that make life easier and more efficient. Our mission is to build technology solutions that are intuitive, efficient, and secure.
      </p>
      <p>
      We strive to be at the forefront of the tech industry, constantly pushing the envelope in order to keep up with the latest trends. We are dedicated to developing products that are reliable, efficient, and flexible.
      </p>
      <p>
      At our core, we believe that technology should be accessible to everyone. We strive to make our products affordable and easy to use, while still maintaining the highest standards of quality.
      </p>
      <p>
      We are committed to providing excellent customer service. Our team of knowledgeable and friendly professionals is here to provide support whenever you need it.
      </p>
      <p>
      We believe that technology should make life better, and that's what we strive for every day. Thank you for choosing us as your tech partner.
      </p>
    </div>
  )
}

function ContactUs() {
  return (
    <div>
<p>
Thank you for your interest in our company!
</p>
<p>
We understand that you may have questions or comments about our products or services and are happy to help. You can reach us through the following channels:
</p>
<p></p>
{/* <p>
Phone: (123) 456-7890
</p> */}
<p>
Email: <a href="mailto:contact@pk-systems.digital">contact@pk-systems.digital</a>
</p>

{/* <p>
Live Chat: Available 24/7
</p> */}

<p>
We strive to respond to all inquiries within one business day.
</p>
<p>
Thank you for your time and we look forward to hearing from you.
</p>
    </div>
  )
}

let currentPage = {
  content: Home(),
  title: 'Home'
}

const App = () => {
  const [currentPage, setCurrentPage] = useState(
    {content: Home(), title: 'Home'}
  );
  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://fonts.googleapis.com/css2?family=Roboto"
    link.rel = "stylesheet"
    document.head.appendChild(link);
  }, []);
  useEffect(() => {
    document.title = 'PK Systems';
  }, []);
  useEffect(() => {
    const link = document.createElement("link");
    link.href = "/favicons/apple-icon-180x180.png"
    link.rel = "apple-touch-icon"
    document.head.appendChild(link);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <meta name="viewport" content="width=device-width, initial-scale=0.33"></meta>
        <a href="/">
        <div className="App-logo-container">
          <div className="App-ball"></div>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        </a>
        <div className="menu">
          <button onClick={() => setCurrentPage({content: Home(), title: 'Home'})}>Home</button>
          <button onClick={() => setCurrentPage({content: Products(), title: 'Products'})}>Products</button>
          <button onClick={() => setCurrentPage({content: Services(), title: 'Services'})}>Services</button>
          <button onClick={() => setCurrentPage({content: Support(), title: 'Support'})}>Support</button>
          <button onClick={() => setCurrentPage({content: Resources(), title: 'Resources'})}>Resources</button>
          <button onClick={() => setCurrentPage({content: AboutUs(), title: 'About Us'})}>About Us</button>
          <button onClick={() => setCurrentPage({content: ContactUs(), title: 'Contact Us'})}>Contact Us</button>
        </div>
      </header>
      <div className="content">
        <div className="box">
          <div className="box-header">
            {currentPage.title}
          </div>
          <div className="box-inner">
            {currentPage.content}
          </div>
        </div>
      </div>
      <footer>
        <a href="#" onClick={() => setCurrentPage({content: Home(), title: 'Home'})}>HOME</a>|
        <a href="#" onClick={() => setCurrentPage({content: Products(), title: 'Products'})}>PRODUCTS</a>|
        <a href="#" onClick={() => setCurrentPage({content: Services(), title: 'Services'})}>SERVICES</a>|
        <a href="#" onClick={() => setCurrentPage({content: Support(), title: 'Support'})}>SUPPORT</a>|
        <a href="#" onClick={() => setCurrentPage({content: Resources(), title: 'Resources'})}>RESOURCES</a>|
        <a href="#" onClick={() => setCurrentPage({content: AboutUs(), title: 'About Us'})}>ABOUT US</a>|
        <a href="#" onClick={() => setCurrentPage({content: ContactUs(), title: 'Contact Us'})}>CONTACT US</a>
        <br></br>
        <br></br>
        <br></br>
        &copy; PK Systems, 2023
      </footer>
    </div>
  );
}

export default App;
